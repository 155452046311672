import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SupportRequestFormInterface } from './support-request-form.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestFormService {

  constructor(private httpClient: HttpClient) {
  }


  submit(data: SupportRequestFormInterface): Observable<any>{
    return this.httpClient.post('{apiNew}/colleges/ipc-request-form', data);
  }
}
